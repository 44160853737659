import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types'
import applePayIcon from 'Apple_Pay_Mark_RGB_041619.svg';
import googlePayIcon from 'google-pay-mark_800.svg';
import creditCardLogos from 'credit_card.png'
import { TermsAndConditions } from 'components'
import { CustomerForm, PaymentForm, WalletForm } from 'forms'

const propTypes = {
  validateRequiredData: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  currentCart: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  setFullAddress: PropTypes.func.isRequired,
}

const PaymentMethod = ({
  handleError,
  validateRequiredData,
  pendingRequest,
  currentCart,
  initialValues,
  updateCustomerInformation,
  setFullAddress
}) => {
  const [activeTab, setActiveTab] = useState(''); 
  const [showApplePayTab, setShowApplePayTab] = useState(false); 

  const checkApplePaySupport = useCallback(async () => {
    try {
      const isSupported = await FreedomPay.Apm.ApplePay.isSupported();
      const canMakePayments = await FreedomPay.Apm.ApplePay.canMakePayments();
      setShowApplePayTab(isSupported && canMakePayments);
    } catch (error) {
      console.error('Error checking Apple Pay support:', error);
      setShowApplePayTab(false);
    }
  }, []);

  useEffect(() => {
    checkApplePaySupport();
  }, [checkApplePaySupport]);

  useEffect(() => {
    if (showApplePayTab) {
      setActiveTab('ApplePay');
    } else {
      setActiveTab('GooglePay'); 
    }
  }, [showApplePayTab]);

  const handlePaymentMethodChange = (event) => {
    setActiveTab(event.target.value);
  };

  return (
    <div className="payment-method">
        <div className="payment-header">
            <h2>Payment Method</h2>
        </div> 
        <div className='freedompay-section'>

          <div className="tabs">
          {showApplePayTab && ( 
            <div className={`tab-row ${activeTab === 'ApplePay' ? 'active' : ''}`}>
              <input
                type="radio"
                name="tab"
                value="ApplePay"
                className="tab-radio"
                checked={activeTab === 'ApplePay'}
                onChange={handlePaymentMethodChange}
              />
              <label className="tab-label">
                Apple Pay
                <img src={applePayIcon} alt="Apple Pay" className="wallet-icon-apple" />
              </label>
            </div>
          )} 
            <div className={`tab-row ${activeTab === 'GooglePay' ? 'active' : ''}`}>
              <input
                type="radio"
                name="tab"
                value="GooglePay"
                className="tab-radio"
                checked={activeTab === 'GooglePay'}
                onChange={handlePaymentMethodChange}
              />
              <label className="tab-label">
                Google Pay
                <img src={googlePayIcon} alt="Google Pay" className="wallet-icon-google" /> 
              </label>
            </div>
            <div className={`tab-row ${activeTab === 'Cards' ? 'active' : ''}`}>
              <input
                type="radio"
                name="tab"
                value="Cards"
                className="tab-radio"
                checked={activeTab === 'Cards'}
                onChange={handlePaymentMethodChange}
              />
              <label className="tab-label">
                <span style={{ marginRight: "1rem" }}>Card</span>
                <img
                  src={creditCardLogos}
                  alt="We accept visa, mastercard, amex and discover cards"
                />
              </label>
            </div>
          </div>
        <div className="payment-method-content">
          {activeTab === 'ApplePay' && (
            <div className="payment-method-content-wallet">
                <TermsAndConditions />
                <WalletForm
                  validateRequiredData={validateRequiredData}
                  handleError={handleError}
                  pendingRequest={pendingRequest}
                  currentCart={currentCart}
                  walletType = "apple"
                />
            </div>
          )}
          {activeTab === 'GooglePay' && (
            <div className="payment-method-content-wallet">
                <TermsAndConditions />
                <WalletForm
                  validateRequiredData={validateRequiredData}
                  handleError={handleError}
                  pendingRequest={pendingRequest}
                  currentCart={currentCart}
                  walletType = "google"
                />
            </div>
          )}
          {activeTab === 'Cards' && (
            <div className="payment-method-content-card"> 
              <CustomerForm
                initialValues={initialValues}
                updateCustomerInformation={updateCustomerInformation}
                setFullAddress={setFullAddress}
                isSinglePage={false}
              />
              <PaymentForm
                validateRequiredData={validateRequiredData}
                handleError={handleError}
                pendingRequest={pendingRequest}
                currentCart={currentCart}
              />
              <TermsAndConditions />
            </div>
          )}
        </div>
        </div>

    </div>
  );
};


PaymentMethod.propTypes = propTypes

export default PaymentMethod;