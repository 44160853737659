import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { SeasonPassDetails, Receipt } from 'components'
import { CouponForm, AddressForm } from 'forms'
import * as api from 'api'

const propTypes = {
  webstore: PropTypes.string.isRequired,
  currentCart: PropTypes.object.isRequired,
  setCurrentCart: PropTypes.func.isRequired,
  selectedPass: PropTypes.object.isRequired,
  updateCartCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  selectNewPass: PropTypes.func.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  setFullAddress: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isSinglePage: PropTypes.bool.isRequired,
}

const defaultProps = {}

function SeasonPass({
  webstore,
  currentCart,
  setCurrentCart,
  selectedPass,
  updateCartCoupon,
  validateCoupon,
  selectNewPass,
  updateCustomerInformation,
  setFullAddress,
  initialValues,
  isSinglePage = true
}) {
  const { token: cartToken } = currentCart

  useEffect(() => {
    updateCartInitialEvent()
  }, [])

  useEffect(() => {
    if(!selectedPass.tickets){
      selectNewPass(selectedPass.id)
    }
  },[selectedPass])

  const updateCartInitialEvent = () => {
    api
      .updateCart({
        webstore,
        cartToken,
        cruiseId: selectedPass.id,
      })
      .then((response) => {
        setCurrentCart(response)
      })
      .catch((error) => console.log(error))
  }

  return (
    <React.Fragment>
      {selectedPass.tickets &&
        <SeasonPassDetails
          {...{ webstore, currentCart, setCurrentCart, selectedPass }}
        />
      }
      <Receipt
        lineItems={currentCart.lineItems}
        taxes={currentCart.taxes}
        discounts={currentCart.discounts}
        couponDetails={currentCart.couponDetails}
        removeCoupon={() => updateCartCoupon('')}
        total={currentCart.total}
        cruiseDisplayName={currentCart.cruiseDisplayName}
        passCheckoutFlow
      />
      {isSinglePage && (
        <AddressForm 
          initialValues={initialValues}  
          updateCustomerInformation= {updateCustomerInformation} 
          setFullAddress={setFullAddress}
        />
      )}
      <CouponForm onSubmit={validateCoupon} />
    </React.Fragment>
  )
}

SeasonPass.propTypes = propTypes
SeasonPass.defaultProps = defaultProps

export default React.memo(SeasonPass)
