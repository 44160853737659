import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CustomerForm, CouponForm } from 'forms'
import { Receipt, BoardingPassDetails } from 'components'
import * as api from 'api'
import { ticketQuantityMap } from 'utils'

const propTypes = {
  event: PropTypes.object,
  fetchEventsForDatepickerOnClick: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  currentCart: PropTypes.object.isRequired,
  setCurrentCart: PropTypes.func.isRequired,
  updateCartCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  editCruiseDetails: PropTypes.func.isRequired,
  editedCruise: PropTypes.object,
  changeCruise: PropTypes.func.isRequired,
  includedDates: PropTypes.array.isRequired,
  initialDatePickerLoading: PropTypes.bool.isRequired,
  convertDatePickerValue: PropTypes.func.isRequired,
  hasCruiseTimes: PropTypes.bool.isRequired,
  standardCruiseTimes: PropTypes.array,
  premierCruiseTimes: PropTypes.array,
  selectedEventId: PropTypes.number,
  updateCartCalendarEvent: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  incrementTicket: PropTypes.func.isRequired,
  decrementTicket: PropTypes.func.isRequired,
  waitingForIncrementOrDecrementResponse: PropTypes.bool.isRequired,
  fetchEventsForDatepickerOnMonthChange: PropTypes.func.isRequired,
  datePickerLoading: PropTypes.bool.isRequired,
  selectedPass: PropTypes.object.isRequired,
  setFullAddress: PropTypes.func.isRequired,
  isSinglePage: PropTypes.bool.isRequired,
}

const defaultProps = {}

function TimedTicketPassFlow({
  event,
  fetchEventsForDatepickerOnClick,
  webstore,
  currentCart,
  setCurrentCart,
  updateCartCoupon,
  validateCoupon,
  customer,
  updateCustomerInformation,
  editCruiseDetails,
  editedCruise,
  changeCruise,
  includedDates,
  initialDatePickerLoading,
  convertDatePickerValue,
  hasCruiseTimes,
  standardCruiseTimes,
  premierCruiseTimes,
  selectedEventId,
  updateCartCalendarEvent,
  selectedDate,
  incrementTicket,
  decrementTicket,
  waitingForIncrementOrDecrementResponse,
  fetchEventsForDatepickerOnMonthChange,
  datePickerLoading,
  selectedPass,
  setFullAddress,
  isSinglePage
}) {
  const { token: cartToken } = currentCart
  const [showTicketSelector, setShowTicketSelector] = useState(false)

  useEffect(() => {
    if(selectedEventId){
      setShowTicketSelector(true)
    }
  }, [selectedEventId])

  useEffect(() => {
    changeCruise(selectedPass.eventTypeId)
    fetchEventsForDatepickerOnClick(selectedPass)
    resetLineItemsOnChange(selectedPass.eventTypeId)
  }, [selectedPass])

  const resetLineItemsOnChange = (eventTypeId, eventStart) => {
    api
      .updateCart({
        webstore,
        cartToken,
        eventTypeId: eventTypeId,
        eventStart: eventStart,
      })
      .then((response) => {
        setCurrentCart(response)
      })
      .catch((error) => console.log(error))
  }

  const admissionLineItems = currentCart.lineItems.filter(li => !li.fee)

  return (
    <React.Fragment>
      <BoardingPassDetails
        editCruiseDetails={editCruiseDetails}
        webstore={webstore}
        editedCruise={editedCruise}
        includedDates={includedDates}
        initialDatePickerLoading={initialDatePickerLoading}
        convertDatePickerValue={convertDatePickerValue}
        hasCruiseTimes={hasCruiseTimes}
        standardCruiseTimes={standardCruiseTimes}
        premierCruiseTimes={premierCruiseTimes}
        selectedEventId={selectedEventId}
        updateCartCalendarEvent={updateCartCalendarEvent}
        tickets={event ? event.tickets : []}
        maxTickets={currentCart.maxTickets}
        selectedDate={selectedDate}
        incrementTicket={incrementTicket}
        decrementTicket={decrementTicket}
        waitingForIncrementOrDecrementResponse={
          waitingForIncrementOrDecrementResponse
        }
        ticketQuantities={ticketQuantityMap(admissionLineItems)}
        lineItems={currentCart.lineItems}
        resetLineItemsOnChange={resetLineItemsOnChange}
        fetchEventsForDatepickerOnMonthChange={
          fetchEventsForDatepickerOnMonthChange
        }
        datePickerLoading={datePickerLoading}
        showTicketSelector={showTicketSelector}
        setShowTicketSelector={setShowTicketSelector}
        currentCart={currentCart}
      />

      <Receipt
        lineItems={currentCart.lineItems}
        taxes={currentCart.taxes}
        discounts={currentCart.discounts}
        couponDetails={currentCart.couponDetails}
        removeCoupon={() => updateCartCoupon('')}
        total={currentCart.total}
        cruiseDisplayName={
          event ? event.displayName : currentCart.cruiseDisplayName
        }
        startDateTime={event ? event.startDateTime : currentCart.startDateTime}
        passCheckoutFlow
      />
      {isSinglePage && event && (
        <React.Fragment>
          <CouponForm onSubmit={validateCoupon} />
          <CustomerForm
            initialValues={{ ...customer }}
            updateCustomerInformation={updateCustomerInformation}
            setFullAddress={setFullAddress}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

TimedTicketPassFlow.propTypes = propTypes
TimedTicketPassFlow.defaultProps = defaultProps

export default React.memo(TimedTicketPassFlow)
