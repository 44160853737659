import React, { useState, useEffect, useCallback } from 'react'
import { useCheckout }  from 'hooks/useCheckout'
import {
  SubmitOrder,
  SeasonPass,
  TimedTicket,
  PassTypeSelector,
  PaymentMethod,
  Receipt,
  TermsAndConditions
} from 'components'
import { CustomerForm } from 'forms'
import { Button } from 'lp-components'
import {
    handleNavButtonClick,
    checkoutTitle,
    updateCartCouponUtil,
    validateCouponUtil,
    removeQueryParam,
    trackAbandonedCart,
    emailField,
    calculateStartDate,
    selectNewPassUtil,
    saveAutofilledCustomerDataUtil,
    saveAutofillCardHolderDataUtil,
    setFullAddressUtil,
    updateCustomerInformationUtil,
    updateCardHolderInformationUtil,
    processOrder,
    handleError,
    creditCardValue,
    validateDataForWallet
} from 'utils/checkoutUtil'
import { emailValidation, postalCodeValidation, phoneValidation } from 'utils/validation'
import sslCheckoutImage from 'sslcheckout.svg'
import { LAYOUT_TYPES, PASS_TYPES } from 'config'

const UnavailableSelectionsMessage = () => (
  <div>
    <p style={{color: 'rgba(0,0,0,.64)'}}>
      Looks like the cruise you were looking at has sold out. <a href="https://circleline.com/sightseeing-cruises" style={{ textDecoration: 'underline', color: 'rgba(0,0,0,.64)' }}>Click here</a> to check out other options!
    </p>
  </div>
)

const CheckoutMultiPage = () => {

  const {
    webstore,
    eventId,
    eventTypeId,
    startDate,
    cart,
    availableCruises,
    termsLink,
    layoutType,
    user,
    abandonedCartEnabled,
  } = useCheckout();

  const [currentCart, setCurrentCart] = useState(cart)
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    removeQueryParam('cart_token');

    if (!abandonedCartEnabled) return

    if (cart.previousSelectionsUnavailable) {
      swal({
        title: "Oh no!",
        content: <UnavailableSelectionsMessage/>,
      })
    }

    const handleBeforeUnload = (event) => {
      trackAbandonedCart(webstore, currentCart);
    };

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [currentCart])
  
  const [customer, setCustomer] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: '',
    country: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    cardHolderFirstName: user.firstName || '',
    cardHolderLastName: user.lastName || '',
    cardHolderCountry: '',
    cardHolderPostalCode: '',
  })
  const [cardHolder, setCardHolder] = useState({
    cardHolderFirstName: user.firstName || '',
    cardHolderLastName: user.lastName || '',
    cardHolderCountry: '',
    cardHolderPostalCode: '',
  })
  const hasItems = currentCart.lineItems.length > 0
  const showPaymentForm = (hasItems && currentCart.total > 0)
  const freeOrder = hasItems && currentCart.total === 0
  const [pendingRequest, setPendingRequest] = useState(false)

  const [showResellerCheckout, setShowResellerCheckout] = useState(false)

  const [selectedPass, setSelectedPass] = useState({
    eventTypeId,
    id: currentCart.cruiseId,
    passType: currentCart.cruisePassType,
    defaultStartDate: calculateStartDate(currentCart.cruiseStartDate, startDate)
  })
  const isSeasonPass = selectedPass.passType === PASS_TYPES.SEASON_PASS


  const updateCustomerInformation = useCallback(
    (customerInformation) => {
        console.log(customer)
        updateCustomerInformationUtil(webstore, currentCart.token, customer, setCustomer, customerInformation)
    },
    [customer]
  )
  
  const updateCardHolderInformation = useCallback(
    (customerInformation) => {
        updateCardHolderInformationUtil(webstore, currentCart.token, cardHolder, setCardHolder, customerInformation)
    },
    [cardHolder]
  )

  const updateCartCoupon = useCallback(
    (coupon) => {
        updateCartCouponUtil({webstore, cartToken: currentCart.token, setCurrentCart, couponCode: coupon})
    }, [currentCart]
  )

  const setFullAddress = useCallback(
    (value) => {
        setFullAddressUtil(value, webstore, currentCart.token, layoutType, customer, setCustomer, showResellerCheckout)
    }, [customer, showResellerCheckout]
  )

  const validateCoupon = useCallback(
    (value) => {
        validateCouponUtil({webstore, cartToken: currentCart.token, setCurrentCart, coupon: value.coupon})
    }, []
  )

  const selectNewPass = useCallback(
    (cruiseId) => {
        selectNewPassUtil(webstore, cruiseId, setSelectedPass)
    }, []
  )

  const saveAutofilledCustomerData = useCallback(
    (paymentMethod) => {
        saveAutofilledCustomerDataUtil(webstore, currentCart.token, customer, setCustomer, paymentMethod)
    },
    [customer]
  )
  

  const saveAutofillCardHolderData = useCallback(
    () => {
        saveAutofillCardHolderDataUtil(webstore, currentCart.token, cardHolder, setCardHolder)
    },
    [cardHolder]
  )
  
  const validateRequiredData = useCallback((
    paymentKey,
    creditCardInformation = [],
    sessionKey,
    paymentMethod
  ) => {

    if('apple' === paymentMethod || 'google' === paymentMethod) {
      validateDataForWallet(webstore, currentCart, setCustomer, pendingRequest, setPendingRequest, 
        paymentKey, creditCardInformation, sessionKey, paymentMethod);
    } else {
      if (!isSeasonPass && !showResellerCheckout) {
        // Customer Form Validity
        const customerFormValid = document
          .getElementById('customer-form')
          .checkValidity()
  
        if (!customerFormValid) {
          handleError(
            'Oops! Missing Customer Information',
            'Please complete the customer information section.'
          )
        }
        
        saveAutofilledCustomerData(paymentMethod);
      }
  
      if(layoutType === 'reseller'){
        saveAutofillCardHolderData();
        // Card Holder Form Validity
        const cardHolderFormValidity = document
        .getElementById("card-holder-form")
        .checkValidity();
        if (!cardHolderFormValidity) {
          handleError(
            'Oops! Missing Card Holder Information',
            'Please complete the card holder information section.'
          )
          return;
        }
      }
  
      const termsAndConditions = document.getElementById('terms-checkbox')
      const recaptchaComplete =
        document.getElementById('recap-response').value === 'true'
  
      if (termsAndConditions && !termsAndConditions.checked) {
        handleError('Oops!', 'Please accept the terms and conditions.')
      } else if (!recaptchaComplete) {
        handleError('Oops!', 'Please complete the reCAPTCHA.')
      } else {
        if (!pendingRequest) {
          let isEverythingValid = false;
          if(!isSeasonPass && !showResellerCheckout){
            const email = document.getElementById('customer-form').email.value
            const phone = document.getElementById('customer-form').phone.value
            const addressLine1 = document.getElementById('customer-form').addressLine1.value
            const city = document.getElementById('customer-form').city.value
            const state = document.getElementById('customer-form').state.value
            const postalCodeValue = document.getElementById('customer-form').postalCode.value
      
            const isEmailValid = emailValidation(email);
            const isPhoneValid = phoneValidation(phone);
            const isPostalCodeValid = postalCodeValidation(postalCodeValue);
  
            if(!(isEmailValid.isValid && isPhoneValid.isValid && isPostalCodeValid.isValid && addressLine1 && city && state)){
              handleError(
                'Oops!',
                'Please provide a valid information in customer information section.'
              )
            } else {
              isEverythingValid = true
            }
          } else if(isSeasonPass) {
            const addressLine1 = document.getElementById('address-form').addressLine1.value
            const city = document.getElementById('address-form').city.value
            const state = document.getElementById('address-form').state.value
            const postalCodeValue = document.getElementById('address-form').postalCode.value
  
            const isPostalCodeValid = postalCodeValidation(postalCodeValue);
            if(!(isPostalCodeValid.isValid && addressLine1 && city && state)){
              handleError(
                'Oops!',
                'Please provide a valid information in address details section.'
              )
            } else {
              isEverythingValid = true
            }
          } else {
            isEverythingValid = true
          }
    
          if(isEverythingValid){
            const postalCode = creditCardValue(creditCardInformation, 'PostalCode')
            const cardNumber = creditCardValue(
              creditCardInformation,
              'MaskedCardNumber'
            )
            const expirationDate = creditCardValue(creditCardInformation, 'ExpirationDate')
            const lastFourDigits = cardNumber
              ? cardNumber.slice(cardNumber.length - 4)
              : ''
            const cardType = creditCardValue(creditCardInformation, 'CardIssuer')
            setPendingRequest(true)
            let data = null;
            if(window.hasOwnProperty('collector') && window.hasOwnProperty('_bcn') && window._bcn.hasOwnProperty('dvc')) {
              data = JSON.stringify(collector.getPayload(_bcn.dvc.getTID()));
            }
            processOrder(webstore, {
              cartToken: currentCart.token,
              sessionKey: sessionKey,
              paymentKey: sessionKey ? paymentKey : '',
              maskedCardNumber: cardNumber,
              cardType,
              lastFourDigits,
              expirationDate: expirationDate ? expirationDate.replace("/","") : '',
              billingPostalCode: postalCode,
            }, data, setPendingRequest)
          }
        }
      }
    }

  },[currentCart, showResellerCheckout]);

  return (
    <div>
      <div className="checkout-header">
        <h1>
          {checkoutTitle(showResellerCheckout, layoutType)}
        </h1>
        <img src={sslCheckoutImage} alt="SSL secure checkout" />
      </div>
      {currentPage === 1 && (
        <>
          {layoutType === LAYOUT_TYPES.PASS && (
            <PassTypeSelector
              availableCruises={availableCruises}
              selectedPass={selectedPass}
              selectNewPass={selectNewPass}
            />
          )}

          {isSeasonPass ? (
            <SeasonPass
              {...{
                webstore,
                currentCart,
                setCurrentCart,
                selectedPass,
                updateCartCoupon,
                validateCoupon,
                selectNewPass,
                updateCustomerInformation,
                setFullAddress,
                isSinglePage: false
              }}
              initialValues={{ ...customer }}
            />
          ) : (
            <TimedTicket
              {...{
                layoutType,
                webstore,
                currentCart,
                setCurrentCart,
                customer,
                updateCustomerInformation,
                updateCardHolderInformation,
                availableCruises,
                updateCartCoupon,
                validateCoupon,
                eventId,
                startDate,
                eventTypeId,
                selectedPass,
                showResellerCheckout,
                setShowResellerCheckout,
                handleError,
                pendingRequest,
                setPendingRequest,
                termsLink,
                saveAutofilledCustomerData,
                hasItems,
                validateRequiredData,
                emailField,
                selectNewPass,
                setFullAddress,
                isSinglePage: false
              }}
            />
          )}
          {showPaymentForm && layoutType != LAYOUT_TYPES.RESELLER && 
            <Button
              style="primary"
              className="button-primary submit-order-button"
              onClick={() => handleNavButtonClick(2, setCurrentPage, currentCart)}
            >
              Continue
            </Button>
          }
        </>
        )}
        {currentPage === 2 && (
          <>
            {showPaymentForm && layoutType != LAYOUT_TYPES.RESELLER && (
                <>
                    <Receipt
                        lineItems={currentCart.lineItems}
                        taxes={currentCart.taxes}
                        discounts={currentCart.discounts}
                        couponDetails={currentCart.couponDetails}
                        total={currentCart.total}
                        cruiseDisplayName={currentCart.cruiseDisplayName}
                        startDateTime={currentCart.startDateTime}
                        currentCart = {currentCart}
                        showBackButton={true}
                        handleBackButtonClick={(value) => handleNavButtonClick(value, setCurrentPage, currentCart)}
                    />
                    <PaymentMethod 
                        validateRequiredData={validateRequiredData}
                        handleError={handleError}
                        pendingRequest={pendingRequest}
                        currentCart={currentCart}
                        initialValues={{ ...customer }}
                        updateCustomerInformation={updateCustomerInformation}
                        setFullAddress={setFullAddress}
                    /> 
                </>
            )}
          </>
        )}  
      {freeOrder && (
        <>
           <CustomerForm
              initialValues={{ ...customer }}
              updateCustomerInformation={updateCustomerInformation}
              setFullAddress={setFullAddress}
            />
            <TermsAndConditions />
            <SubmitOrder
              onSubmit={validateRequiredData}
              pendingRequest={pendingRequest}
            />
        </>
      )}
    </div>
  )
}

export default CheckoutMultiPage