import React, { useEffect, useState } from 'react'
import { useCheckout }  from 'hooks/useCheckout'
import Recaptcha from 'react-google-recaptcha'
import { RenderedHtml } from 'components'
import * as api from 'api'


const TermsAndConditions = () => {
   
  const {
    termsLink,
    warningText,
  } = useCheckout();

  const googleCaptchaSiteKey = process.env.GOOGLE_CAPTCHA_SITE_KEY
  const [recaptchaSuccessful, setRecaptchaSuccessful] = useState(false);

  const handleCaptcha = (recaptchaResponse) => {
    api.verifyRecaptcha({ recaptchaResponse })
      .then((response) => {
        if (response.success) {
          setRecaptchaSuccessful(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="terms-section">
      { termsLink && (
        <label>
          <input type="checkbox" name="terms" id="terms-checkbox" />
          {''}I agree to the
          <a href={termsLink} target="_blank" className="terms-and-conditions">
            {''} Terms and Conditions
          </a>
        </label>
      )}
      { warningText && (
        <div style={{margin: "1rem 0"}}> 
          <RenderedHtml>{warningText}</RenderedHtml>
        </div>
      )}
        <div className="recaptcha">
          <Recaptcha
            sitekey={googleCaptchaSiteKey}
            onChange={handleCaptcha}
            onExpired={() => setRecaptchaSuccessful(false)}
            onErrored={() => setRecaptchaSuccessful(false)}
          />
          <input
            readOnly
            id="recap-response"
            type="hidden"
            name="success"
            value={recaptchaSuccessful}
          />
        </div>
    </div>
  )
}

export default TermsAndConditions
